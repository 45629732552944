import React from 'react';

import {
  Box,
  Flex,
  Text,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  ModalFooter,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from 'axios';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';
import { useQueryCache } from 'react-query';
import { Link } from 'react-router-dom';

import {
  Badge,
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import { TIME_FORMAT } from '@/constants/date';
import {
  INVOICE_REQUEST_RESPONSES_LABEL,
  INVOICE_REQUEST_RESPONSES_STATUS,
} from '@/constants/invoiceRequest';
import { useUserContext } from '@/context/user-context';
import { useRefuseInvoiceRequestResponse } from '@/services/invoiceRequest';
import { formatAddress } from '@/utils/textUtils';

export const ResponseCard = ({
  invoiceRequestResponse,
  invoiceRequestId,
  ...otherProps
}) => {
  const { user: currentUser } = useUserContext();

  const { agency, status, startDate, endDate, price } = invoiceRequestResponse;
  const { address: agencyAddress, name: agencyName } = agency;
  const {
    RECEIVED,
    UNAVAILABLE,
    WAITING,
    REFUSED,
  } = INVOICE_REQUEST_RESPONSES_STATUS;

  const toast = useToast();
  const queryCache = useQueryCache();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalSize = useBreakpointValue({
    base: 'xs',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'md',
  });

  const [refuseMutate, { isLoading }] = useRefuseInvoiceRequestResponse({
    onError: () => {
      toast({
        title:
          'Une erreur est survenue lors du refus de la proposition de devis',
        status: 'error',
      });
    },
    onSuccess: () => {
      queryCache.invalidateQueries(`invoiceRequests/${invoiceRequestId || ''}`);
      onClose();
      toast({
        title: 'La proposition de devis a été refusée avec succès',
        status: 'success',
      });
    },
  });

  const address = formatAddress(agencyAddress);

  const handleRefuseMutate = () => {
    refuseMutate({
      id: invoiceRequestId,
      responseId: invoiceRequestResponse?.id,
    });
  };

  const handleOpenEstimate = async () => {
    try {
      const response = await axios.patch(
        `invoice-requests/${invoiceRequestId}/responses/${invoiceRequestResponse?.id}/visualizeEstimate/${invoiceRequestResponse?.estimate}`,
        {
          estimate: invoiceRequestResponse?.estimate,
        },
        {
          responseType: 'arraybuffer',
        }
      );
      console.log(response);
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open(fileURL, '_blank');

      pdfWindow.onload = () => {
        URL.revokeObjectURL(fileURL);
      };
    } catch (error) {
      toast({
        title: 'Une erreur est survenue lors de la récupération du devis',
        status: 'error',
      });
    }
  };

  const agencyDetails = {
    [RECEIVED]: {
      feedback: (
        <>
          Cette agence vous a envoyé un devis sur votre adresse mail{' '}
          {currentUser?.email ? ': ' : ''}
          <Text as="span" color="gray.600">
            {` ${currentUser?.email || ''}`}
          </Text>
        </>
      ),
      response: (
        <Flex
          justifyContent={{ base: 'center', md: 'flex-end' }}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Button
            size="sm"
            colorScheme="brandSecondary"
            mb={{ base: 3, md: 0 }} // Espacement entre les boutons sur les petits écrans
            mr={{ base: 0, md: 3 }} // Espacement entre les boutons sur les grands écrans
            onClick={handleOpenEstimate}
          >
            Visualiser le devis
          </Button>
          <Button
            size="sm"
            colorScheme="danger"
            mb={{ base: 3, md: 0 }} // Espacement entre les boutons sur les petits écrans
            mr={{ base: 0, md: 3 }} // Espacement entre les boutons sur les grands écrans
            onClick={onOpen}
          >
            Refuser le devis
          </Button>
          <Button
            as={Link}
            size="sm"
            colorScheme="brandSecondary"
            mb={{ base: 3, md: 0 }} // Espacement entre les boutons sur les petits écrans
            to={`/bookings/${invoiceRequestId}/response/${invoiceRequestResponse?.id}/accept`}
          >
            Accepter le devis
          </Button>
        </Flex>
      ),
    },
    [WAITING]: {
      feedback: "Cette agence n'a pas encore répondu à votre demande.",
    },
    [UNAVAILABLE]: {
      feedback: "Cette agence n'est pas disponible",
    },
    [REFUSED]: {
      feedback: 'Vous avez refusé le devis proposé par cette agence',
    },
  };

  const startDateObj = dayjs(startDate).isValid() ? dayjs(startDate) : null;
  const endDateObj = dayjs(endDate).isValid() ? dayjs(endDate) : null;
  const dateToDisplay = startDateObj
    ? startDateObj.format('DD MMMM YYYY')
    : 'N/A';
  const startTimeToDisplay = startDateObj
    ? startDateObj.format(TIME_FORMAT)
    : 'N/A';
  const endTimeToDisplay = endDateObj ? endDateObj.format(TIME_FORMAT) : 'N/A';

  return (
    <>
      <Card borderWidth="0" {...otherProps}>
        <CardContent position="relative">
          <Flex>
            <Box mr={20}>
              <CardTitle>{agencyName}</CardTitle>
              <CardTitle mt={2}>{dateToDisplay}</CardTitle>
              <CardTitle as="h3" size="sm">
                {`${startTimeToDisplay} à ${endTimeToDisplay}`}
              </CardTitle>
            </Box>
          </Flex>
          <CardDescription
            height="auto"
            textAlign="left"
            h={agencyDetails[status]?.response ? '11rem' : '6rem'}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            pb={2}
          >
            <Box>
              <Text fontSize="sm" mt={3} isTruncated>
                <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
                {address}
              </Text>
              {agencyDetails[status]?.feedback && (
                <Text fontSize="sm" mt={6} color="gray.400">
                  <Icon icon={FaInfoCircle} color="inherit" mr="2" />
                  {agencyDetails[status]?.feedback}
                </Text>
              )}
            </Box>
            {!!agencyDetails[status]?.response && (
              <Box mt={6}>{agencyDetails[status]?.response}</Box>
            )}
          </CardDescription>
          <Badge
            position="absolute"
            top="1"
            right="0"
            colorScheme={
              INVOICE_REQUEST_RESPONSES_LABEL[status]?.color || 'gray'
            }
          >
            {INVOICE_REQUEST_RESPONSES_LABEL[status]?.name || ''}
          </Badge>
          {!!price && (
            <Heading as="h3" position="absolute" top="10" right="0" size="md">
              {`${parseFloat(price || 0).toFixed(2)} € TTC`}
            </Heading>
          )}
        </CardContent>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" size="lg">
              Refuser ce devis
            </Heading>
            <Heading as="h3" size="md" pt={2}>
              Êtes-vous sûr de vouloir refuser le devis proposé par cette
              agence?
            </Heading>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" size="sm" mr={3} onClick={onClose}>
              Abandonner
            </Button>
            <Button
              size="sm"
              colorScheme="danger"
              isDisabled={isLoading}
              onClick={handleRefuseMutate}
              isLoading={isLoading}
            >
              Refuser ce devis
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ResponseCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  invoiceRequestResponse: PropTypes.object,
  invoiceRequestId: PropTypes.string,
};

ResponseCard.defaultProps = {
  invoiceRequestResponse: {},
  invoiceRequestId: null,
};
